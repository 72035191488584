import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import Order, { OrderType } from "./Order"
import PaymentReport from "./PaymentReport"
import F1Form from "./F1Form"
import TinkoffForm from "./TinkoffForm"
import StripeForm from "./StripeForm"

import "./App.css"
import EventBus, { EventCollector } from "./EventBus"

enum InvoiceStatus
{
	Indeterminate = 0,
	Fault = 1,
	Expired =  2,
	Denied = 3,
	Unknown = 4,
	Issued = 5,
	Punched = 6,
	Canceled = 7
}

export default function Invoice()
{
	const {t} = useTranslation('App', {keyPrefix: 'Invoice'});

	let paramset = useParams();

	const [pin, setPIN] = useState(0);
	const [status, setStatus] = useState(InvoiceStatus.Indeterminate);
	const [order, setOrder] = useState<Order | null>(null);

	const event_collector: EventCollector = new EventCollector();

	const check = (code: number): boolean =>
	{
		if (code == 200) {
			return true;
		} else if (code == 401) {
			setStatus(InvoiceStatus.Denied);
		} else if (code == 404) {
			setStatus(InvoiceStatus.Unknown);
		} else {
			setStatus(InvoiceStatus.Fault);
		}		
		setOrder(null);
		console.error("Error checked: ", code);
		return false;
	}

	const request = (shop: string, guid: string): boolean =>
	{
		setStatus(InvoiceStatus.Indeterminate);
		fetch("https://aes.cadabra.cloud/v1/" + shop + "/" + guid + "?pin=" + pin.toString(), { method: "GET" })
		.then(
				(response) =>
				{
					if (!response.ok) {
						check(response.status)
						return ;
					}
					response.json().then(
										(body) =>
										{
											let o = new Order();
											if (!o.deserialize(body)) {
												setStatus(InvoiceStatus.Fault);
												return ;
											}
											o.reset_callback = request;
											setOrder(o);
											if (!o.punched) {
												if (o.type == OrderType.Payment) {
													setStatus(InvoiceStatus.Issued);
												} else if (o.expired) {
													setStatus(InvoiceStatus.Expired);
													/// @??? setStatus(InvoiceStatus.Canceled);
												} else {
													setStatus(InvoiceStatus.Issued);
												}
											} else if (InvoiceStatus.Issued == status && o.request && o.request?.hasBackURL()) {
												return window.location.replace(o.request.getBackURL(true));
											} else {
												setStatus(InvoiceStatus.Punched);
											}
										},
										(error) =>
										{
											check(500);
											console.log("Fault: ", error);
										}
					);
				},
				(error) =>
				{
					check(500);
					console.log("Fault: ", error);
				}
		);
		return true;
	}

	event_collector.register(
								'invoice.status',
								(value: string) =>
								{
									if (InvoiceStatus[value as keyof typeof InvoiceStatus] == status) {
										return ;
									} else if ((paramset.shop ? paramset.shop.length : 0) >= 0 && (paramset.guid ? paramset.guid.length : 0) >= 0) {
										request(paramset.shop!, paramset.guid!);
									} else {
										setStatus(InvoiceStatus.Fault);
									}
								}
	);

	useEffect(
				() =>
				{
					if ((paramset.shop ? paramset.shop.length : 0) > 0 && (paramset.guid ? paramset.guid.length : 0) > 0) {
						request(paramset.shop!, paramset.guid!);
					} else {
						setStatus(InvoiceStatus.Fault);
					}
				},
				[]
	)

	if (InvoiceStatus.Indeterminate == status) {
		return <h1 className="AppTitle" style={{marginBottom: 0}}>{t("Processing...")}</h1>
	} else if (InvoiceStatus.Issued == status && order && order.valid) {
		if (order.type == OrderType.Template) {
			return (
					<>
						<h1 className="AppTitle" style={{marginBottom: 0}}>{order.subscription ? t("Subscription") : t("Payment")} {t("at")} {order.shop}</h1>
						<h6 className="AppPaymentHeader" style={{marginTop: 0, marginBottom: 0}}>{order.guid}</h6>
						<F1Form order={order} />
						<h5 className="AppCopyrightFooter">«Cadabra(æ)Pay» by Cadabra, {new Date().getFullYear()}</h5>
					</>
			);
		} else if (order.currency_code == 'RUB') {
			return (
					<>
						<h1 className="AppTitle" style={{marginBottom: 0}}>{order.subscription ? t("Subscription") : t("Payment")} #{order.no} {t("at")} {order.shop}</h1>
						<h6 className="AppPaymentHeader" style={{marginTop: 0, marginBottom: 0}}>{order.guid}</h6>
						<TinkoffForm order={order} />
						<h5 className="AppCopyrightFooter">«Cadabra(æ)Pay» by Cadabra, {new Date().getFullYear()}</h5>
					</>
			);
		} else {
			return (
					<>
						<h1 className="AppTitle" style={{marginBottom: 0}}>{order.subscription ? t("Subscription") : t("Payment")} #{order.no} {t("at")} {order.shop}</h1>
						<h6 className="AppPaymentHeader" style={{marginTop: 0, marginBottom: 0}}>{order.guid}</h6>
						<StripeForm order={order} />
						<h5 className="AppCopyrightFooter">«Cadabra(æ)Pay» by Cadabra, {new Date().getFullYear()}</h5>
					</>
			)
		}
	} else if (InvoiceStatus.Unknown == status) {
		return (
				<>
					<h1 className="AppTitle" style={{marginBottom: 0}}>{t("Unknown invoice")} ;|</h1>
					<h3 className="AppCopyrightFooter">[ {t("Feel free to email us at pay@cadabra.cloud")} ]</h3>
					<h5 className="AppCopyrightFooter">«Cadabra(æ)Pay» by Cadabra, {new Date().getFullYear()}</h5>
				</>
		);
	} else if (InvoiceStatus.Fault == status) {
		return (
				<>
					<h1 className="AppTitle" style={{marginBottom: 0}}>{t("Unexpected error")} :\</h1>
					<h3 className="AppCopyrightFooter">[ {t("Feel free to email us at pay@cadabra.cloud")} ]</h3>
					<h5 className="AppCopyrightFooter">«Cadabra(æ)Pay» by Cadabra, {new Date().getFullYear()}</h5>
				</>
		);
	} else if (InvoiceStatus.Punched == status && order && order.valid) {
		return (
				<>
					<h1 className="AppTitle" style={{marginBottom: 0}}>{order.subscription ? t("Subscription") : t("Payment")} #{order.no} {t("at")} {order.shop}</h1>
					<h6 className="AppPaymentHeader" style={{marginTop: 0, marginBottom: 0}}>{order.guid}</h6>
					<PaymentReport order={order} />
					<h5 className="AppCopyrightFooter">«Cadabra(æ)Pay» by Cadabra, {new Date().getFullYear()}</h5>
				</>
		);
	} else if (InvoiceStatus.Expired == status) {
		return (
				<>
					<h1 className="AppTitle" style={{marginBottom: 0}}>{t("Access denied")} :|</h1>
					<h3 className="AppCopyrightFooter">[ {t("Sorry, but this invoice has been expired")} ]</h3>
					<h5 className="AppCopyrightFooter">«Cadabra(æ)Pay» by Cadabra, {new Date().getFullYear()}</h5>
				</>
		);
	}
	return <></>
}