import * as React from 'react';
import {withTranslation} from 'react-i18next';

import Order from '../Order'

import './Asset/Style/index.css'

type PaymentReportInterface = {	
	order: Order;
	t: (arg1: string, arg2?: string) => string;
}

enum RequestStatus
{
	Indeterminate = -2,
	Await = -1,
	Determinate = 0,
	Fault = 1
}

type PaymentReportState = {
	status: RequestStatus
}

class PaymentReport extends React.Component<PaymentReportInterface, PaymentReportState>
{
	state: PaymentReportState = {
		status: RequestStatus.Determinate
	};

	constructor(propset: PaymentReportInterface)
	{
		super(propset);

		this.onCancelRequest = this.onCancelRequest.bind(this);

		if (!this.props.order) {
			this.state.status = RequestStatus.Indeterminate;
		}
	}

	componentDidMount(): void
	{

	}

	onCancelRequest(event: React.MouseEvent<HTMLButtonElement>): void
	{	
		event.preventDefault();

		this.setState({status: RequestStatus.Await});
		fetch("https://aes.cadabra.cloud/v1/" + this.props.order.shop + "/" + this.props.order.guid + "?pin=" + this.props.order.toString(), { method: "DELETE" })
		.then(
				(response) =>
				{
					this.setState({status: response.ok ? RequestStatus.Indeterminate : RequestStatus.Fault});
				},
				(error) =>
				{
					this.setState({status: RequestStatus.Fault});
				}
		);
	}

	render()
	{
		return (
				<div className="PaymentReport">
					<div className="PaymentReportInner">
						<div>
							<label htmlFor="full_name">
								{this.props.t("Full Name")}
							</label>
							<input value={this.props.order.customer_name} name="full_name" type="text" autoComplete="off" placeholder={this.props.t("Unknown")} disabled={true} />
						</div>

						<div>
							<label htmlFor="email_address">
								{this.props.t("Email Address")}
							</label>
							<input value={this.props.order.customer_email_address} name="email_address" type="email" autoComplete="off" placeholder={this.props.t("Unknown")} disabled={true} />
						</div>

						<div>
							<label htmlFor="phone_number">
								{this.props.t("Phone Number")}
							</label>
							<input value={this.props.order.customer_phone_number} name="phone_number" type="tel" autoComplete="off" placeholder="0 000 000 0000" disabled={true} />
						</div>

						<ol>
							{this.props.order.basket.map(
															item => (
																<li key={item.sku}>
																	{item.name} ({this.props.order.currency_formatter.format(item.price / 100)} x {item.quantity}) = <b>{this.props.order.currency_formatter.format(item.cost / 100)}</b>{!item.lifetime && <sup>({item.interval == 1 ? this.props.t("everyday") : this.props.t("every") + " ".concat(item.interval.toString(), " " + this.props.t("days"))})</sup>}
																	<ul>
																		<li>
																			<i>{item.sku}</i>
																		</li>
																	</ul>
																</li>
															)
							)}
						</ol>
						<b>{this.props.t("Total")}</b>: <strong>{this.props.order.formatted_total}</strong>{this.props.order.interval > 0 && <sup>({this.props.order.interval == 1 ? this.props.t("everyday") : this.props.t("every") + " ".concat(this.props.order.interval.toString(), " " + this.props.t("days"))})</sup>}

						<hr />

						{this.props.order.issue_date && <div>{this.props.t("Issued")}: {this.props.order.issue_date.toLocaleString()}</div>}
						{this.props.order.expired && <div>{this.props.t("Expired")}: {this.props.order.expire_date.toLocaleString()}</div>}
						{this.props.order.punched && <div>{this.props.t("Paid")}: {this.props.order.punch_date.toLocaleString()}</div> }
						{this.props.order.canceled && <div>{this.props.t("Canceled")}: {this.props.order.expire_date.toLocaleString()}</div>}
						{this.props.order.refunded && <div>{this.props.t("Refunded")}: {this.props.order.expire_date.toLocaleString()}</div>}

						<div className="PaymentReportBottom">
							{(!this.props.order.refunded && !this.props.order.canceled && !this.props.order.expired && this.props.order.punched) && <button onClick={this.onCancelRequest} disabled={this.state.status == RequestStatus.Determinate ? false : true}>
								{
									(
										() =>
										{
											switch(this.state.status)
											{
												case RequestStatus.Fault:
													return this.props.t("Unexpected error") + " :(";

												case RequestStatus.Await:
													return this.props.t("Requesting...");

												case RequestStatus.Indeterminate:
													return this.props.order.subscription ? this.props.t("Cancellation was requested!") : this.props.t("Refund was requested!");

												default:
													return this.props.order.subscription ? this.props.t("Request a cancellation") : this.props.t("Request a refund");
											}
										}
									)()
								}
							</button>}
						</div>
					</div>
				</div>
		);
	}
}

export default withTranslation('PaymentReport')(PaymentReport)