import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {Stripe, StripeElements, loadStripe} from "@stripe/stripe-js";
import {Elements, PaymentElement, useStripe, useElements} from "@stripe/react-stripe-js";

import EventBus from '../EventBus';
import Order from '../Order'

import './Asset/Style/index.css'

enum PurchaseStatus
{
	None = 0,
	Load = 1,
	Process = 2, /// "Your payment is processing."
	Idle = 3,
	Succeed = 4,
	CardError = 5,
	ValidationError = 6,
	PayMethodError = 7, /// "Your payment was not successful, please try again."
	UnknownError = 8 /// "Something went wrong."
}

type StripeElementInterface = {
	secret: string;
	order: Order;
}

function StripeElement(propset: StripeElementInterface)
{
	const {t} = useTranslation('StripeForm');

	const stripe: Stripe | null = useStripe();
	const elements: StripeElements | null = useElements();
	
	const [status, setStatus] = useState(PurchaseStatus.None);
	const [message, setMessage] = useState<string | null>(null);

	useEffect(
				() =>
				{
					if (!stripe) {
						setStatus(PurchaseStatus.None);
						return;
					}
					setStatus(PurchaseStatus.Load);
					stripe.retrievePaymentIntent(propset.secret)
					.then(
							({ paymentIntent }) =>
							{
								switch (paymentIntent ? paymentIntent.status : "") {
									case "succeeded":
										setStatus(PurchaseStatus.Succeed);
										EventBus.dispatch<string>('invoice.status', "Punched");
										break;
									case "processing":
										setStatus(PurchaseStatus.Process);
										break;
									case "requires_payment_method":
										setStatus(PurchaseStatus.PayMethodError);
										break;
									default:
										setStatus(PurchaseStatus.UnknownError);
										break;
								}
							}
					);
				},
				[stripe]
	);

	const onSubmit = async(event: React.FormEvent<HTMLFormElement>): Promise<void> =>
	{
		event.preventDefault();
		if (!stripe || !elements) {
			// Stripe.js has not yet loaded, make sure to disable form submission until Stripe.js has loaded.
			return;
		}
		setStatus(PurchaseStatus.Load);
		const { error } = await stripe.confirmPayment(
														{
															elements,
															confirmParams: {
																			return_url: window.location.href.toString(),
															},
														}
		);
		/**
		 * This point will only be reached if there is an immediate error when
		 * confirming the payment. Otherwise, your customer will be redirected to
		 * your `return_url`. For some payment methods like iDEAL, your customer will
		 * be redirected to an intermediate site first to authorize the payment, then
		 * redirected to the `return_url`.
		 */		
		if (error.type === "card_error") {
			setStatus(PurchaseStatus.CardError);
			setMessage(error.message ?? "Card error.");
		} else if (error.type === "validation_error") {
			setStatus(PurchaseStatus.ValidationError);
			setMessage(error.message ?? "Validation error.");
		} else {
			setStatus(PurchaseStatus.UnknownError);
			setMessage(error.message ?? "An unexpected error occurred.");
		}
	};

	const onChange = async(event: Object): Promise<void> =>
	{
		console.log(event);
	};

	const isBusy = (): boolean =>
	{
		return status == PurchaseStatus.Load || status == PurchaseStatus.Process;
	}

	return (			
			<form id="payment-form" className="StripeForm" onSubmit={onSubmit}>
				<PaymentElement className="StripeFormElement" onChange={onChange} />
				<button id="submit" disabled={isBusy() || !stripe || !elements}>
					<span id="button-text">{isBusy() ? <div id="spinner" className="StripeFormSpinner"></div> : t("Pay now") + " " + propset.order.formatted_total + (propset.order.vat > 0 ? (" (+VAT " + propset.order.formatted_vat + ")") : "")}</span>
				</button>
				{message && <div className="StripeFormMessage">{message}</div>}
			</form>
	);
}

enum FormStatus
{
	Unavailable = -3,
	Error = -2,
	Invalid = -1,
	Unknown = 0,
	Valid = 1,
	Forward = 2,
	Complete = 3
}

type StripeFormInterface = {
	order?: Order;
}

export default function StripeForm(propset: StripeFormInterface)
{
	const {t} = useTranslation('StripeForm');

	const [status, setStatus] = useState<FormStatus>(FormStatus.Unknown);
	const [publishable_key, setPublishableKey] = useState<string | null>(null);
	const [client_secret, setClientSecret] = useState<string | null>(null);	
	const [promise, setPromise] = useState<Promise<Stripe | null> | null>(null);

	const authorize = function(shop: string, guid: string, attemp: number = 10, expect_status?: string)
	{
		if (--attemp <= 0) {
			console.error("Fault: Too much requests...");
			setStatus(FormStatus.Unavailable);
			return ;
		}

		fetch("https://aes.cadabra.cloud/stripe/" + shop + "/" + guid, { method: "GET" })
		.then(
				(response) =>
				{
					if (!response.ok) {
						console.error("Fault: HTTP error");
						setStatus(FormStatus.Error);
						return ;
					}
					response.json()
					.then(
							(body) =>
							{
								const _publishable_key: string = body.hasOwnProperty("publishable_key") ? body.publishable_key : "";
								const _client_secret: string = body.hasOwnProperty("client_secret") ? body.client_secret : "";
								const _payment_status: string = body.hasOwnProperty("payment_status") ? body.payment_status : "";

								if (_client_secret.length <= 0 || _publishable_key.length <= 0) {
									console.error("Fault: Payment keys are unavailable");
									setStatus(FormStatus.Error);
									return ;
								} else if (expect_status && expect_status != _payment_status) {
									setTimeout(() => authorize(shop, guid, attemp, expect_status), 2500);
									return ;
								} else if (_payment_status == 'succeeded') {
									setStatus(FormStatus.Complete);
									EventBus.dispatch<string>('invoice.status', "Punched");
									return ;
								} else if (propset.order) {
									propset.order.vat = body.payment_invoice.tax ?? 0;
								}

								/**
								 * Make sure to call loadStripe outside of a component’s render to avoid
								 * recreating the Stripe object on every render.
								 * This is your test publishable API key.
								 */
								const _promise: Promise<Stripe | null> = loadStripe(_publishable_key);

								setPublishableKey(_publishable_key);
								setClientSecret(_client_secret);
								setPromise(_promise);
								setStatus(FormStatus.Valid);
							},
							(error) =>
							{
								console.error("Fault: ", error);
								setStatus(FormStatus.Error);
							}
					);
				},
				(error) =>
				{
					console.error("Fault: ", error);
					setStatus(FormStatus.Error);
				}
		);
	}

	useEffect(
				() =>
				{
					if (!propset.order) {
						return;
					}
					const query_paramset: URLSearchParams = new URLSearchParams(window.location.search);
					authorize(propset.order.shop, propset.order.guid, 10, (query_paramset.has('redirect_status') ? query_paramset.get('redirect_status') ?? undefined : undefined));
				},
				[]
	);

	return (
			<Elements options= {{clientSecret: client_secret ?? "", appearance: { theme: 'flat', variables: { colorBackground: '#fafafa', colorText: '#1a3b5d'} }}} stripe={promise ?? null}>
				{
					(
						() =>
						{
							switch (status)
							{
								case FormStatus.Unknown:
									return <h3 className="StripeFormTitle">{t("Loading...")}</h3>;
								case FormStatus.Unavailable:
									return <h3 className="TinkoffFormTitle">{t("Unfortunately, payment information is not available now")} :\</h3>
								case FormStatus.Invalid:
									return <h3 className="StripeFormTitle">{t("Payment is no longer available")}</h3>
								case FormStatus.Forward:
									 /// @todo Need a link to force
									return <h3 className="TinkoffFormTitle">{t("Forwarding...")}</h3> 
								case FormStatus.Complete:
									return <h3 className="TinkoffFormTitle">{t("Payment succeeded!")}</h3>
								case FormStatus.Valid: 
									if (client_secret && propset.order) {
										return <StripeElement secret={client_secret} order={propset.order} />
									} else {
										return <h3 className="StripeFormTitle">{t("Unfortunately, payment information is not available now")} :\</h3>
									}

								default:
									return <h3 className="StripeFormTitle">{t("Sorry, but payment system is unavailable now")} :(</h3>
							}
						}
					)()
				}
			</Elements>
	);
}