import * as React from 'react'
import {withTranslation} from 'react-i18next'

import Order from '../Order'

import './Asset/Style/index.css'

type F1FormInterface = {	
	order: Order;
	t: (arg1: string, arg2?: string) => string;
}

enum RequestStatus
{
	Indeterminate = -2,
	Await = -1,
	Determinate = 0,
	Fault = 1
}

type F1FormState = {
	status: RequestStatus;
	name: string;
	name_valid: boolean;
	email_address: string;
	email_address_valid: boolean;
	phone_number: string;
	phone_number_valid: boolean;
}

class F1Form extends React.Component<F1FormInterface, F1FormState>
{
	state: F1FormState = {
		status: RequestStatus.Determinate,
		name: "",
		name_valid: false,
		email_address: "",
		email_address_valid: false,
		phone_number: "",
		phone_number_valid: false
	};

	constructor(propset: F1FormInterface)
	{
		super(propset);

		this.onNameChange = this.onNameChange.bind(this);
		this.onPhoneChange = this.onPhoneChange.bind(this);
		this.onEmailChange = this.onEmailChange.bind(this);
		this.onInvoiceRequest = this.onInvoiceRequest.bind(this);

		if (!this.props.order) {
			this.state.status = RequestStatus.Indeterminate;
		} else if (this.props.order.request && this.props.order.request.hasExchangeRate()) {
			let exchange: [string, number] = this.props.order.request.getExchangeMap().entries().next().value;
			this.props.order.exchange(exchange[0], exchange[1]);
		}
	}

	componentDidMount(): void
	{

	}

	onNameChange(event: React.ChangeEvent<HTMLInputElement>): void
	{
		const valid: boolean = event.target.value.length >= 2;
		this.setState(
						{
							name: event.target.value,
							name_valid: valid
						}
		);
		event.target.setCustomValidity(valid ? "" : this.props.t("Incorrect name"));
	}

	onPhoneChange(event: React.ChangeEvent<HTMLInputElement>): void
	{
		var number: string = event.target.value.replace(/[^\d|-||\(|\)| ]/g, '');
		if ('89' == number) {
			number = '79';
		} else if ('0' == number) {
			return ;
		}
		const valid: boolean = /[0-9]{8,30}/g.test(number);
		this.setState(
						{
							phone_number: number,
							phone_number_valid: valid
						}
		);
		event.target.setCustomValidity(valid ? "" : this.props.t("Incorrect phone number"));
	}

	onEmailChange(event: React.ChangeEvent<HTMLInputElement>): void
	{
		const valid: boolean = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(event.target.value);
		this.setState(
						{
							email_address: event.target.value,
							email_address_valid: valid
						}
		);
		event.target.setCustomValidity(valid ? "" : this.props.t("Incorrect email address"));
	}

	onInvoiceRequest(event: React.MouseEvent<HTMLButtonElement>): void
	{
		event.preventDefault();

		let url_target_param_list: Array<string> = [];
		const url_source_param_set: URLSearchParams = new URLSearchParams(window.location.search);
/*
		if (pin > 0) {
			url_target_param_list.push("pin=" + pin.toString());
		}
*/
		if (url_source_param_set.has('utm_source')) {
			url_target_param_list.push("utm_source=" + url_source_param_set.get('utm_source'));
		}
		if (url_source_param_set.has('utm_medium')) {
			url_target_param_list.push("utm_medium=" + url_source_param_set.get('utm_medium'));
		}
		if (url_source_param_set.has('utm_campaign')) {
			url_target_param_list.push("utm_campaign=" + url_source_param_set.get('utm_campaign'));
		}
		if (url_source_param_set.has('utm_content')) {
			url_target_param_list.push("utm_content=" + url_source_param_set.get('utm_content'));
		}
		if (url_source_param_set.has('utm_term')) {
			url_target_param_list.push("utm_term=" + url_source_param_set.get('utm_term'));
		}
		this.setState({status: RequestStatus.Await});
		fetch(
				"https://aes.cadabra.cloud/v1/" + this.props.order.shop + "?" + url_target_param_list.join("&"),
				{
					method: "POST",
					body: JSON.stringify(
											{
												items: this.props.order.guid,
												currency: this.props.order.currency_code,
												name: this.state.name,
												phone_number: this.state.phone_number,
												email_address: this.state.email_address
											}
					),
					headers: {
								'Content-Type': 'application/json'
					}
				}
		)
		.then(
				(response) =>
				{
					if (!response.ok) {
						this.setState({status: RequestStatus.Fault});
						return ;
					}
					response.json().then(
										(body) =>
										{
											if (!body.hasOwnProperty('shop') || !body.hasOwnProperty('guid')) {
												this.setState({status: RequestStatus.Fault});
											} else if (this.props.order.reset_callback) {
												this.props.order.reset_callback(body.shop, body.guid);
											} else {
												this.setState({status: RequestStatus.Indeterminate});
											}
										},
										(error) =>
										{
											this.setState({status: RequestStatus.Fault});
										}
					);
				},
				(error) =>
				{
					this.setState({status: RequestStatus.Fault});
				}
		);
	}

	render()
	{
		return (
				<div className="F1Form">
					<div className="F1FormInner">
						<div>
							<label htmlFor="full_name">
								{this.props.t("Full Name")}
							</label>
							<input readOnly={(RequestStatus.Determinate != this.state.status)} required={true} name="name" type="text" autoComplete="off" placeholder="Super Hero" onChange={this.onNameChange} value={this.state.name} />
						</div>

						<div>
							<label htmlFor="email_address">
								{this.props.t("Email Address")}
							</label>
							<input readOnly={(RequestStatus.Determinate != this.state.status)} required={true} name="email" type="email" autoComplete="off" placeholder="human@earth.uni" onChange={this.onEmailChange} value={this.state.email_address} />
						</div>

						<div>
							<label htmlFor="phone_number">
								{this.props.t("Phone Number")}
							</label>
							<input readOnly={(RequestStatus.Determinate != this.state.status)} required={true} name="phone" type="tel" autoComplete="off" placeholder="1 814 300 8368" onChange={this.onPhoneChange} value={this.state.phone_number} />
						</div>

						<ol>
							{this.props.order.basket.map(
															item => (
																<li key={item.sku}>
																	{item.name} ({this.props.order.currency_formatter.format((item.price * item.currency_rate) / 100)} x {item.quantity}) = <b>{this.props.order.currency_formatter.format(item.cost / 100)}</b>{!item.lifetime && <sup>({item.interval == 1 ? this.props.t("everyday") : this.props.t("every") + " ".concat(item.interval.toString(), " " + this.props.t("days"))})</sup>}
																	<ul>
																		<li>
																			<i>{item.sku}</i>
																		</li>
																	</ul>
																</li>
															)
							)}
						</ol>
						<b>{this.props.t("Total")}</b>: <strong>{this.props.order.formatted_total}</strong>{this.props.order.interval > 0 && <sup>({this.props.order.interval == 1 ? this.props.t("everyday") : this.props.t("every") + " ".concat(this.props.order.interval.toString(), " " + this.props.t("days"))})</sup>}

						<hr />

						{this.props.order.issue_date && <div>{this.props.t("Issued")}: {this.props.order.issue_date.toLocaleString()}</div>}
						{this.props.order.expired && <div>{this.props.t("Expired")}: {this.props.order.expire_date.toLocaleString()}</div>}

						<div className="F1FormTitle">
						{
							(
								() =>
								{
									switch (this.state.status)
									{
										case RequestStatus.Await:
											return <button type="submit" disabled={true}>{this.props.t("Loading...")}</button>
										case RequestStatus.Fault: 
											return <button type="submit" disabled={true}>{this.props.t("Unexpected Error")}</button>
										default:
											return <button type="submit" onClick={this.onInvoiceRequest} disabled={(RequestStatus.Determinate != this.state.status || !this.state.name_valid || !this.state.email_address_valid || !this.state.phone_number_valid)}>{this.props.t("Pay now")} {this.props.order.formatted_total}<sup style={{fontSize: ".5em"}}>{(!this.props.order.subscription) ? "" : this.props.order.interval == 1 ? " " + this.props.t("everyday") : this.props.t("every").concat(" ", this.props.order.interval.toString(), " " + this.props.t("days"))}</sup></button>
									}
								}
							)()
						}
						</div>
					</div>
				</div>
		);
	}
}

export default withTranslation('F1Form')(F1Form)