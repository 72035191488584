export interface RegistryInteface
{
	unregister: () => void;
}

export interface CallableInteface
{
	[key: string]: Function;
}

export interface SubscriberInteface
{
	[key: string]: CallableInteface;
}

export interface EventBusInterface
{
	dispatch<T>(event: string, arg?: T): void;
	register(event: string, callback: Function): RegistryInteface;
}

export class EventCollector
{
	private array: Array<RegistryInteface> = [];

	register(event: string, callback: Function): void
	{
		this.array.push(Singleton.register(event, callback));
	}

	cleanup(): void
	{
		this.array.forEach((value) => value.unregister());
		this.array = [];
	}
}

class EventBus implements EventBusInterface
{
	private subscriber: SubscriberInteface;
	private next_id: number;
  
	constructor()
	{
		this.next_id = 0;
		this.subscriber = {};
	}
  
	public dispatch<T>(event: string, arg?: T): void
	{
		const subscriber = this.subscriber[event];
		if (subscriber === undefined) {
			return;
		}

		Object.keys(subscriber).forEach((key) => subscriber[key](arg));
	}
  
	public register(event: string, callback: Function): RegistryInteface
	{
		const id = this.next_id++;
		if (!this.subscriber[event]) {
			this.subscriber[event] = {};
		}
		this.subscriber[event][id] = callback;
		return {
				unregister: () => {
					delete this.subscriber[event][id];
					if (Object.keys(this.subscriber[event]).length === 0) {
						delete this.subscriber[event];
					}
				}
		};
	}
}
const Singleton: EventBus  = new EventBus();
export default Singleton as EventBus;