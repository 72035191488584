import './App.css';

export default function About()
{
	return (
			<>
				<h5 className="AppCopyrightFooter">«Cadabra æ Pay» by Cadabra. {new Date().getFullYear()}</h5>
			</>
	);
}
