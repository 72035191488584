
import Serializable from "./Serializable";
import { default as GoogleTagManager } from "react-gtm-module"

import { EventCollector } from "./EventBus"

export class Item implements Serializable<Item>
{
	name: string = "";
	sku: string = "";
	price: number = 0;
	currency_rate: number = 1;
	quantity: number = 0;
	interval: number = 0;

	public get valid(): boolean
	{
		/// @todo
		return true;
	}
	
	public get cost(): number
	{
		return this.price * this.currency_rate * this.quantity;
	}

	public get lifetime(): boolean
	{
		return 0 == this.interval;
	}

	deserialize(input: any): boolean
	{
		if (!input.hasOwnProperty('name')) {
			return false;
		}
		this.name = input.name;

		if (!input.hasOwnProperty('sku')) {
			return false;
		}
		this.sku = input.sku;

		if (!input.hasOwnProperty('price')) {
			return false;
		}
		this.price = input.price;

		if (!input.hasOwnProperty('quantity')) {
			return false;
		}
		this.quantity = input.quantity;

		if (input.hasOwnProperty('duration')) {
			this.interval = input.duration;
		} else if (input.hasOwnProperty('interval')) {
			this.interval = input.interval;
		} else {
			return false;
		}

		return true;
	}
}

interface Request
{
	getExchangeMap(): Map<string, number>;
	hasExchangeRate(): boolean;

	getBackURL(success: boolean): string;
	hasBackURL(): boolean;

	deserialize(input: any): boolean
}

class TildaRequest implements Request, Serializable<TildaRequest>
{
	order_id: string = "";
	description: string = "";
	successback_url: string = "";
	failback_url: string = "";

	public get valid(): boolean
	{
		return this.order_id.length > 0;
	}

	getExchangeMap(): Map<string, number>
	{
		return new Map<string, number>();
	}

	hasExchangeRate(): boolean
	{
		return false;
	}

	getBackURL(success: boolean): string
	{
		return success ? this.successback_url : this.failback_url;
	}

	hasBackURL(): boolean
	{
		return this.successback_url.length > 0 && this.failback_url.length > 0;
	}

	deserialize(input: any): boolean
	{
		if (!input.hasOwnProperty('order_id')) {
			return false;
		}
		this.order_id = input.order_id;

		if (!input.hasOwnProperty('description')) {
			this.description = input.description;
		}

		if (input.hasOwnProperty('successback_url')) {
			this.successback_url = input.successback_url;
		}
		if (input.hasOwnProperty('failback_url')) {
			this.failback_url = input.failback_url;
		}

		return true;
	}
}

class F1Request implements Request, Serializable<F1Request>
{
	exchange_map: Map<string, number> = new Map<string, number>();

	public get valid(): boolean
	{
		return true;
	}

	getExchangeMap(): Map<string, number>
	{
		return this.exchange_map;
	}

	hasExchangeRate(): boolean
	{
		return this.exchange_map.size > 0;
	}

	getBackURL(success: boolean): string
	{
		return "";
	}

	hasBackURL(): boolean
	{
		return false;
	}

	deserialize(input: any): boolean
	{
		if (!input.hasOwnProperty('exchange')) {
			return false;
		}
		this.exchange_map = new Map<string, number>(Object.entries(input.exchange));
		return true;
	}
}

interface Meta
{
	deserialize(input: any): boolean
}

class GTMMeta implements Meta, Serializable<GTMMeta>
{
	id: string = "";

	order?: Order;

	get valid(): boolean
	{
		return (this.id.length > 0);
	}

	deserialize(input: any): boolean
	{
		if (!input.hasOwnProperty('id')) {
			return false;
		}
		this.id = input.id;
		return true;
	}

	gtag(): void
	{
		
	}

	listen(order: Order): (event_name: string) => void
	{
		if (!this.order) {
			this.order = order;
			GoogleTagManager.initialize
			(
				{
					gtmId: this.id,
					dataLayerName: "dataLayer",
				}
			);
			GoogleTagManager.dataLayer
			(
				{
					dataLayerName: "dataLayer",
					dataLayer:
					{
						event: 'gtm.ready',
						eventModel: {},
						eventCallback: function()
						{

							const url_parameters = new URLSearchParams(window.location.search);
							if (url_parameters.has('ga4_cid') && url_parameters.has('ga4_sid')) {
								const g_taglist = Object.keys((window as any)['google_tag_manager']).filter(function(key) { return key.substring(0, 2) == 'G-'; } );
								if (g_taglist.length > 0) {
									GoogleTagManager.dataLayer
									(
										{
											dataLayerName: "dataLayer",
											dataLayer: [
														'config',
														g_taglist[0],
														{
															'client_id': url_parameters.get('ga4_cid'),
															'session_id': url_parameters.get('ga4_sid')
														}
											]
										}
									);
								}
							}
						}
					}
				}
			);
			let cache_item: string | null = localStorage.getItem('crn:cc:ae:invoice:' + this.order.guid + ':gtm');
			if (cache_item && cache_item == "Issued" && order.punched) {
				this.track("Punched");
			} else if (!cache_item && !order.punched && !order.canceled && !order.expired && !order.refunded) {
				this.track("Issued");
			}
		}
		return this.track.bind(this);
	}

	track(value: string): void
	{
		let event_name: string = "";
		if (!this.order) {
			return ;
		} else if ("Issued" == value) {
			localStorage.setItem('crn:cc:ae:invoice:' + this.order.guid + ':gtm', value);
			GoogleTagManager.dataLayer
			(
				{
					dataLayerName: "dataLayer",
					dataLayer:
					{
						event: "add_payment_info",
						eventModel:
						{
							payment_type: "aerarium",
							currency: this.order.currency_code,
							value: (this.order.total / 100),
							items: Array.from
							(
								this.order.basket,
								(item) =>
								(
									{
										item_id: item.sku,
										quantity: item.quantity,
										price: (item.price * item.currency_rate) / 100
									}
								)
							)
						}
					}
				}
			);
		} else if ("Punched" == value) {
			localStorage.removeItem('crn:cc:ae:invoice:' + this.order.guid + ':gtm');
			GoogleTagManager.dataLayer
			(
				{
					dataLayerName: "dataLayer",
					dataLayer:
					{
						event: "purchase",
						eventModel:
						{
							transaction_id: this.order.guid,
							currency: this.order.currency_code,
							value: (this.order.total / 100),
							items: Array.from
							(
								this.order.basket,
								(item) =>
								(
									{
										item_id: item.sku,
										quantity: item.quantity,
										price: (item.price * item.currency_rate) / 100
									}
								)
							)
						}
					}
				}
			);
		} else {
			return ;
		}
	}
}

export enum OrderType
{
	Template = 0,
	Payment = 1
}

export default class Order implements Serializable<Order>
{
	shop: string = "";
	guid: string = "";
	currency_code: string = "XXX";
	basket: Array<Item> = [];
	customer_name: string = "";
	customer_email_address: string = "";
	customer_phone_number: number = 0;
	expire: number = 0;
	issue: number = 0;
	punch: number = 0;
	interval: number = 0;
	referral: number = 0;
	vat: number = 0;
	request?: Request;
	meta: Map<string, Meta> = new Map<string, Meta>();

	event_collector?: EventCollector;
	reset_callback?: (shop: string, guid: string) => boolean;

	public get no(): number
	{
		return this.issue;
	}

	public get issue_date(): Date
	{
		return new Date(this.issue * 1000);
	}

	public get expire_date(): Date
	{
		return new Date(this.expire * 1000);
	}

	public get punch_date(): Date
	{
		return new Date(this.punch * 1000);
	}

	public get formatted_customer_phone_number(): string
	{
		return (this.customer_phone_number <= 0) ? "" : this.customer_phone_number.toString();
	}

	public get currency_formatter(): Intl.NumberFormat
	{
		return new Intl.NumberFormat(
										'en-US',
										{
											style: 'currency',
											currency: this.currency_code
										},
//										minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
//										maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
		);
	}

	public get total(): number
	{
		return this.basket.reduce(
									(previous: number, current: Item) => previous + current.cost,
									0
		);
	}

	public get formatted_total(): string
	{
		return this.currency_formatter.format(this.total / 100);
	}

	public get formatted_vat(): string
	{
		return this.currency_formatter.format(this.vat / 100);
	}

	public get subscription(): boolean
	{
		return this.interval > 0 || this.basket.some((item: Item) => item.interval > 0);
	}

	public get issued(): boolean
	{
		return this.issue > 0;
	}

	public get punched(): boolean
	{
		return this.punch > 0;
	}

	public get expired(): boolean
	{
		return this.expire >= this.issue && !this.punched;
	}

	public get canceled(): boolean
	{
		return this.expire > this.punch && this.punch > 0 && this.subscription;
	}

	public get refunded(): boolean
	{
		return this.expire > this.punch && this.punch > 0 && !this.subscription;
	}

	public get type(): OrderType
	{
		return this.punch < 0 ? OrderType.Template : OrderType.Payment;
	}

	public get valid(): boolean
	{
		return true;
	}

	public exchange(code: string, rate: number): void
	{
		for (let item of this.basket) {
			item.currency_rate = rate;
		}
		this.currency_code = code;
	}

	deserialize(input: any): boolean
	{
		this.event_collector = new EventCollector();

		if (!input.hasOwnProperty('shop')) {
			return false;
		}
		this.shop = input.shop;

		if (!input.hasOwnProperty('guid')) {
			return false;
		}
		this.guid = input.guid;

		if (!input.hasOwnProperty('currency')) {
			return false;
		}
		this.currency_code = input.currency;

		if (!input.hasOwnProperty('basket')) {
			return false;
		}
		const basket: Array<any> = input.basket;
		for (let i of basket) {
			let item: Item = new Item();
			if (!item.deserialize(i)) {
				return false;
			}
			this.basket.push(item);
		}

		if (!input.hasOwnProperty('customer_name')) {
			return false;
		}
		this.customer_name = input.customer_name;

		if (!input.hasOwnProperty('customer_email_address')) {
			return false;
		}
		this.customer_email_address = input.customer_email_address;

		if (!input.hasOwnProperty('customer_phone_number')) {
			return false;
		}
		this.customer_phone_number = parseInt(input.customer_phone_number);

		if (!input.hasOwnProperty('expire')) {
			return false;
		}
		this.expire = parseInt(input.expire);

		if (!input.hasOwnProperty('issue')) {
			return false;
		}
		this.issue = parseInt(input.issue);

		if (!input.hasOwnProperty('punch')) {
			return false;
		}
		this.punch = parseInt(input.punch);

		if (!input.hasOwnProperty('interval')) {
			return false;
		}
		this.interval = parseInt(input.interval);

		if (!input.hasOwnProperty('referral')) {
			return false;
		}
		this.referral = parseInt(input.referral);

		if (input.hasOwnProperty('request') && input.request.hasOwnProperty('proto') && input.request.hasOwnProperty('spec')) {
			switch (input.request.proto) {
				case 'tilda':
					this.request = new TildaRequest();
					break;
/*
				case 'v1':
					this.request = new V1Request();
					break;
*/
				case 'f1':
					this.request = new F1Request();
					break;

				default:
					this.request = undefined;
			}
			if (this.request && !this.request.deserialize(input.request.spec)) {
				return false;
			}
		}

		if (input.hasOwnProperty('meta')) {
			if (input.meta.hasOwnProperty('GTM')) {
				const meta: GTMMeta = new GTMMeta();
				if (meta.deserialize(input.meta.GTM)) {
					this.event_collector.register("invoice.status", meta.listen(this));
					this.meta.set('GTM', meta);
				} else {
					console.error("GTM initialization error");
				}
			}
		}

		return true;
    }
}