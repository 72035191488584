import * as React from 'react';
import {withTranslation} from 'react-i18next';

import EventBus from '../EventBus';
import Order from '../Order'

import './Asset/Style/index.css'
import { t } from 'i18next';

type TinkoffFormInterface = {
	order: Order;
	t: (arg1: string, arg2?: string) => string;
}

enum FormStatus
{
	Unavailable = -3,
	Error = -2,
	Invalid = -1,
	Unknown = 0,
	Valid = 1,
	Forward = 2,
	Complete = 3
}

type TinkoffFormState = {
	status: FormStatus
}

class TinkoffForm extends React.Component<TinkoffFormInterface, TinkoffFormState>
{
	state: TinkoffFormState = {
		status: FormStatus.Unknown
	}

	componentDidMount()
	{
		const query_paramset: URLSearchParams = new URLSearchParams(window.location.search);
		let status: string | undefined = undefined;
		if (query_paramset.has("status")) {
			status = query_paramset.get("status") ?? undefined;
		}  else if (document.referrer.indexOf("tinkoff") >= 0) {
			status = "CONFIRMED"; /// < back from tinkoff
		}
		this.authorize(this.props.order['shop'], this.props.order['guid'], 10, status ?? undefined);
	}

	authorize(shop: string, guid: string, attemp: number = 10, expect_status?: string)
	{
		if (--attemp <= 0) {
			console.error("Fault: Too much requests...");
			this.setState({status: FormStatus.Unavailable});
			return ;
		}

		// @todo detect&pass pin
		fetch("https://aes.cadabra.cloud/tinkoff/" + shop + "/" + guid, { method: "GET" })
		.then(
				(response) =>
				{
					if (!response.ok) {
						console.error("Fault: HTTP error");
						this.setState({status: FormStatus.Error});
						return ;
					}
					response.json()
					.then(
							(body) =>
							{
								const url: string = body.hasOwnProperty("url") ? body.url : "";
								const status: string = body.hasOwnProperty("status") ? body.status : "UNKNOWN";
								if (url.length <= 0 || status == "UNKNOWN") {
									console.error("Fault: Basic credentials are unspecified");
									this.setState({status: FormStatus.Error});
									return ;
								} else if (expect_status && expect_status != status) {
									setTimeout(() => this.authorize(shop, guid, attemp, expect_status), 2500);
									return ;
								} else if (status == "NEW") {
									this.setState({status: FormStatus.Forward}); /// < or .Valid when form implemented here
									window.location.replace(url);
									return ;
								} else if (status == "CONFIRMED") {
									this.setState({status: FormStatus.Complete});
									EventBus.dispatch<string>('invoice.status', "Punched");
									return ;
								}
								this.setState({status: FormStatus.Valid});
							},
							(error) =>
							{
								console.error("Fault: ", error);
								this.setState({status: FormStatus.Error});
							}
					);
				},
				(error) =>
				{
					console.error("Fault: ", error);
					this.setState({status: FormStatus.Error});
				}
		);
	}
	
	render()
	{
		return (
				<div>
					{
						(
							() =>
							{
								switch (this.state.status)
								{
									case FormStatus.Unknown:
										return <h3 className="TinkoffFormTitle">{this.props.t("Loading...")}</h3>
									case FormStatus.Unavailable:
										return <h3 className="TinkoffFormTitle">{this.props.t("Unfortunately, payment information is not available now")} :\</h3>
									case FormStatus.Invalid:
										return <h3 className="TinkoffFormTitle">{this.props.t("Payment is expired or invalid")} :(</h3>
									case FormStatus.Forward:
										 /// @todo Need a link to force
										return <h3 className="TinkoffFormTitle">{this.props.t("Forwarding...")}</h3>
									case FormStatus.Complete:
										return <h3 className="TinkoffFormTitle">{this.props.t("Payment succeeded!")}</h3>

									/// @note Valid is now unimplemented 
									default:
										return <h3 className="TinkoffFormTitle">{this.props.t("Sorry, but payment system is unavailable now")} :|</h3>
								}
							}
						)()
					}
				</div>
		)
	}
}
export default withTranslation("TinkoffForm")(TinkoffForm)