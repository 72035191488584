import { createRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import i18n, { Module } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import App from './App';
import './index.css';

import En from './en.json';
import Ru from './ru.json';

import F1FormEn from './F1Form/Asset/i18n/en.json';
import F1FormRu from './F1Form/Asset/i18n/ru.json';

import V1FormEn from './V1Form/Asset/i18n/en.json';
import V1FormRu from './V1Form/Asset/i18n/ru.json';

import PaymentReportEn from './PaymentReport/Asset/i18n/en.json';
import PaymentReportRu from './PaymentReport/Asset/i18n/ru.json';

import StripeFormEn from './StripeForm/Asset/i18n/en.json';
import StripeFormRu from './StripeForm/Asset/i18n/ru.json';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.init(
		{
			fallbackLng: 'en',
			interpolation: {
							escapeValue: false
			},
			resources: {
						'en': {
								App: En,
								V1Form: V1FormEn,
								F1Form: F1FormEn,
								StripeForm: StripeFormEn,
								PaymentReport: PaymentReportEn
						},
						'ru': {
								App: Ru,
								V1Form: V1FormRu,
								F1Form: F1FormRu,
								StripeForm: StripeFormRu,
								PaymentReport: PaymentReportRu
								
						}
			}
		}
);

const element = document.getElementById('root');
const root = createRoot(element!);
root.render(<App />);