import { BrowserRouter, Routes, Route } from "react-router-dom"

import About from "./About"
import Invoice from "./Invoice"

import './App.css'

function App() {
	return (
			<BrowserRouter>
				<div className="App">
					<Routes>
						<Route path='*' element={<About />} />
						<Route path='invoice/:shop/:guid' element={<Invoice />} />
					</Routes>
				</div>
			</BrowserRouter>
	);
}

export default App;
